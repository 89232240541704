import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import WebContainer from "../WebContainer/Index";
import Title from "../Title/Index";
import Image from "../ImageTag/Index";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const Specialities = ({ Data }) => {
  if (!Data || !Data.Data) {
    return null;
  }

  const showArrows = Data.Data.length > 2;

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: showArrows,
    // arrows: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: Data.Data.length > 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: Data.Data.length > 1,
        },
      },
    ],
  };
  console.log(Data);

  return (
    <WebContainer _parentClass="specialities">
      <Title
        firstHeading={Data.title}
        secondHeading={"Comprehensive Range of Packages for Every Need"}
        // headingThree={true}
      />
      <div className="col-12 m-auto slider doctors">
        <Slider {...settings}>
          {Data.Data.map((packageData, index) => (
            <div key={index} className="">
              <div className="_card mx-lg-3">
                <div className="card_header">
                  <h4 className="flex-center">{packageData.title}</h4>
                </div>
                <div className="card_body">
                  <div className="specilitiesSlide px-4">
                    <h3>
                      <strong>{packageData.testsTitle}</strong>
                    </h3>
                    <ul>
                      {packageData.content &&
                        packageData.content.map((test, lineIndex) => (
                          <li key={lineIndex}>
                            <span
                              dangerouslySetInnerHTML={{ __html: test }}
                            ></span>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="cardfooter">
                    <div className="my-2">
                      <span className="px-2 pr-5">Actual Price</span>
                      <span>
                        <strong>₹ {packageData.actual_price}/-</strong>
                      </span>
                    </div>
                    <div className="my-2">
                    <span className="px-2">Bundled Price</span>
                      <span>
                        <strong>₹ {packageData.bundle_price}/-</strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </WebContainer>
  );
};

Specialities.propTypes = {
  Data: PropTypes.shape({
    title: PropTypes.string,
    Data: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        link: PropTypes.string,
        content: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default Specialities;
